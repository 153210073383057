import { ApolloError, useQuery } from '@apollo/client';
import {
  Box,
  Button,
  Center,
  Flex,
  HStack,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { ColumnFilterPopover, DataTable } from '@gamma/data-table';
import { MuiIcon } from '@gamma/display';
import { TimestampCell } from '@gamma/investigator/components';
import { graphqlErrorRedirects, ROUTES } from '@gamma/investigator/constants';
import {
  useDateRangeQueryString,
  useIsNewSearch,
  useManualPagination,
} from '@gamma/investigator/hooks';
import { i18n } from '@gamma/investigator/localization';
import {
  Alert,
  AlertEntity,
  AlertSummary,
  CustomSearchRuleAlert,
  Detection,
  IQueryAlertsPaginated,
  MlAlert,
  MLModels,
  NoticeAlert,
  QUERY_ALERTS_ML_PAGINATED,
  QUERY_ALERTS_NOTICE_PAGINATED,
  QUERY_ALERTS_SEARCH_BASED_PAGINATED,
  QUERY_ALERTS_SURICATA_PAGINATED,
  SuricataAlert,
} from '@gamma/investigator/queries';
import { FlushPanelContent, Panel } from '@gamma/layout';
import { RouterLink } from '@gamma/navigation';
import { Drawer } from '@gamma/overlay';
import { GraphQLReqStatus, LoadingSpinner } from '@gamma/progress';
import {
  DetectionAlertsFilters,
  IDetectionContext,
} from 'libs/investigator/pages/detections/src/lib/Pages/Detection/Detection';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { SortingRule, Column as TableColumn } from 'react-table';

import {
  AlertRowSplitML,
  AlertRowSplitNotice,
  AlertRowSplitSearchBased,
  AlertRowSplitSuricata,
  MLButtonsCol,
  NoticeButtonsCol,
  SuricataButtonsCol,
} from './Components';

export const DetectionAlertsFooter = ({
  loading,
  error,
  data,
}: {
  loading: boolean;
  error: ApolloError | undefined;
  data: Alert[] | undefined;
}) => {
  if (loading || error) {
    return (
      <Panel>
        <GraphQLReqStatus
          loading={loading}
          error={error}
          extended={graphqlErrorRedirects}
        />
      </Panel>
    );
  }
  if (!data?.length) {
    return <Panel>{i18n.pages.alerts.na}</Panel>;
  } else {
    return null;
  }
};

interface AlertEntityPopoverProps {
  entities?: string[];
}

export const AlertEntityPopover = ({ entities }: AlertEntityPopoverProps) => {
  return entities && entities?.length === 1 ? (
    <Text>{entities[0]}</Text>
  ) : entities && entities?.length > 1 ? (
    <HStack>
      <Text>{entities[0]}</Text>
      <Popover trigger="hover" placement="top">
        <PopoverTrigger>
          <Text color="text.link">+{entities?.length - 1}</Text>
        </PopoverTrigger>
        <Portal>
          <PopoverContent>
            <PopoverArrow />
            <PopoverBody>
              <VStack alignItems="start">
                {entities
                  ?.slice(1, entities?.length)
                  .map((entity) => <Text>{entity}</Text>)}
              </VStack>
            </PopoverBody>
          </PopoverContent>
        </Portal>
      </Popover>
    </HStack>
  ) : (
    <>---</>
  );
};

export type SanitizedMlAlert = {
  alert_timestamp: any;
  alert_info: any;
  alert_entity: any;
  pipeline: string;
  models: MLModels[];
  timestamp: number;
  logscale_query: string;
  source_entities?: string[];
  destination_entities?: string[];
  related_entities: AlertEntity[];
  average_technique_score_map: string;
};

const mlDataTableColumns: TableColumn<SanitizedMlAlert>[] = [
  {
    Header: 'Timestamp',
    accessor: 'timestamp',
    Cell: (props) => {
      return <TimestampCell timestamp={props.value} isFromNow={false} />;
    },
  },
  {
    Header: 'Source',
    accessor: 'source_entities',
    disableSortBy: true,
    Filter: ColumnFilterPopover,
    Cell: (props) => <AlertEntityPopover entities={props?.value} />,
  },
  {
    Header: 'Destination',
    accessor: 'destination_entities',
    disableSortBy: true,
    Filter: ColumnFilterPopover,
    Cell: (props) => <AlertEntityPopover entities={props?.value} />,
  },
  {
    Header: 'Actions',
    accessor: 'logscale_query',
    disableSortBy: true,
    Cell: (props) => {
      const {
        models,
        pipeline,
        alert_info,
        alert_entity,
        alert_timestamp,
        related_entities,
        average_technique_score_map,
      } = props.row.original;
      return (
        <MLButtonsCol
          item={{
            models,
            pipeline,
            alert_info,
            alert_entity,
            alert_timestamp,
            related_entities,
            average_technique_score_map,
          }}
          logScaleQuery={props.value}
        />
      );
    },
  },
];

export type SanitizedSearchBasedAlert = {
  alert_info: any;
  alert_entity: any;
  alert_timestamp: any;
  source_entities?: string[];
  destination_entities?: string[];
  timestamp: number;
};

const searchBasedDataTableColumns: TableColumn<SanitizedSearchBasedAlert>[] = [
  {
    Header: 'Timestamp',
    accessor: 'timestamp',
    Cell: (props) => {
      return <TimestampCell timestamp={props.value} isFromNow={false} />;
    },
  },
  {
    Header: 'Source',
    accessor: 'source_entities',
    disableSortBy: true,
    Filter: ColumnFilterPopover,
    Cell: (props) => <AlertEntityPopover entities={props?.value} />,
  },
  {
    Header: 'Destination',
    accessor: 'destination_entities',
    disableSortBy: true,
    Filter: ColumnFilterPopover,
    Cell: (props) => <AlertEntityPopover entities={props?.value} />,
  },
];

export type SanitizedNoticeAlert = {
  uid: string;
  msg?: string;
  alert_info: any;
  alert_entity: any;
  alert_timestamp: any;
  source_entities?: string[];
  destination_entities?: string[];
  timestamp?: number;
  logscale_query?: string;
};

const noticeDataTableColumns: TableColumn<SanitizedNoticeAlert>[] = [
  {
    Header: 'Timestamp',
    accessor: 'timestamp',
    Cell: (props) => {
      return (
        props?.value && (
          <TimestampCell timestamp={props.value} isFromNow={false} />
        )
      );
    },
  },
  {
    Header: 'Source',
    accessor: 'source_entities',
    disableSortBy: true,
    Filter: ColumnFilterPopover,
    Cell: (props) => <AlertEntityPopover entities={props?.value} />,
  },
  {
    Header: 'Destination',
    accessor: 'destination_entities',
    disableSortBy: true,
    Filter: ColumnFilterPopover,
    Cell: (props) => <AlertEntityPopover entities={props?.value} />,
  },
  {
    Header: 'Actions',
    accessor: 'logscale_query',
    disableSortBy: true,
    Cell: (props) => {
      const { msg, uid, alert_info, alert_entity, alert_timestamp } =
        props.row.original;

      return (
        <NoticeButtonsCol
          item={
            {
              uid,
              msg,
              alert_info,
              alert_entity,
              alert_timestamp,
            } as NoticeAlert
          }
          logScaleQuery={props.value}
        />
      );
    },
  },
];

export type SanitizedSuricataAlert = {
  uid: string;
  msg?: string;
  alert_info: any;
  alert_entity: any;
  alert_timestamp: any;
  source_entities?: string[];
  destination_entities?: string[];
  timestamp?: number;
  logscale_query?: string;
  community_id?: string;
  destination_ip?: string;
  payload?: string;
};

const suricataDataTableColumns: TableColumn<SanitizedSuricataAlert>[] = [
  {
    Header: 'Timestamp',
    accessor: 'timestamp',
    Cell: (props) => {
      return (
        props?.value && (
          <TimestampCell timestamp={props.value} isFromNow={false} />
        )
      );
    },
  },
  {
    Header: 'Source',
    accessor: 'source_entities',
    disableSortBy: true,
    Filter: ColumnFilterPopover,
    Cell: (props) => <AlertEntityPopover entities={props?.value} />,
  },
  {
    Header: 'Destination',
    accessor: 'destination_entities',
    disableSortBy: true,
    Filter: ColumnFilterPopover,
    Cell: (props) => <AlertEntityPopover entities={props?.value} />,
  },
  {
    Header: 'Actions',
    accessor: 'logscale_query',
    disableSortBy: true,
    Cell: (props) => {
      const {
        msg,
        uid,
        alert_info,
        alert_entity,
        community_id,
        destination_ip,
        alert_timestamp,
      } = props.row.original;

      return (
        <SuricataButtonsCol
          item={
            {
              uid,
              alert_info,
              alert_entity,
              community_id,
              destination_ip,
              alert_timestamp,
            } as SuricataAlert
          }
          logScaleQuery={props.value}
        />
      );
    },
  },
];

interface DetectionAlertsProps {
  detectionData: Detection;
  suricataRuleText: string;
  getAppConfigurationsData: string | null;
}

export const DetectionAlerts = ({
  detectionData,
  suricataRuleText,
  getAppConfigurationsData,
}: DetectionAlertsProps) => {
  const { metadataLoading, metadataData } =
    useOutletContext<IDetectionContext>();

  const { controls, alertActivity, alertsActivity } = i18n.pages.detections;

  const { logSearch } = ROUTES;

  const [downloadUrl, setDownloadUrl] = useState<string>('');

  const { start, end } = useDateRangeQueryString();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const detection = detectionData;

  const detectionAlertType = detection?.alert_info?.alert_type;

  const {
    sortBy: alertsSortBy,
    pageSize: alertsPageSize,
    pageCount: alertsPageCount,
    pageIndex: alertsPageIndex,
    onFetchData: onFetchAlertsData,
    getPageCount: alertsGetPageCount,
    getSortsForAPI: alertsGetSortsForAPI,
    formatNestedSorts: alertsFormatNestSorts,
  } = useManualPagination<AlertSummary>({
    sortBy: [{ id: 'timestamp', desc: true }],
  });

  const query =
    detectionAlertType === 'ml'
      ? QUERY_ALERTS_ML_PAGINATED
      : detectionAlertType === 'notice'
        ? QUERY_ALERTS_NOTICE_PAGINATED
        : detectionAlertType === 'suricata_corelight'
          ? QUERY_ALERTS_SURICATA_PAGINATED
          : detectionAlertType === 'custom_search_rule'
            ? QUERY_ALERTS_SEARCH_BASED_PAGINATED
            : QUERY_ALERTS_SEARCH_BASED_PAGINATED;

  const alertsSortedTimestamp = alertsFormatNestSorts(alertsSortBy);

  const [filters, setFilters] = useState<
    { id: string; value: string }[] | null
  >(null);

  const { isNewSearch } = useIsNewSearch({
    filters,
  });

  const [alertsSize, setAlertsSize] = useState<number>(10);
  const [alertsOffset, setAlertsOffset] = useState<number>(0);

  const noFilterVariables = {
    size: alertsSize,
    offset: isNewSearch ? 0 : alertsOffset,
    sort: alertsGetSortsForAPI(alertsSortedTimestamp),
    query: `{"query": {"bool": {"must": [{"term": {"alert_info.content_id": "${
      detection?.alert_info?.content_id
    }"}}, {"term": {"alert_entity.entity_id": "${
      detection?.alert_entity?.entity_id
    }"}}, {"range": {"alert_timestamp.end": {"gte": ${
      detection?.earliest_start_timestamp
    }}}}, {"range": {"alert_timestamp.start": {"lte": ${
      detection?.latest_start_timestamp
    }}}}${detection?.tenant_info?.tenant_id ? ',' : ''}${
      detection?.tenant_info?.tenant_id
        ? `{"terms":{"tenant":["${detection?.tenant_info?.tenant_id}"]}}`
        : ''
    }]}}}`,
  };

  const [selectedItem, setSelectedItem] = useState<
    CustomSearchRuleAlert | MlAlert | NoticeAlert | SuricataAlert
  >();

  const {
    loading: alertsLoading,
    error: alertsError,
    data: alertsData,
    refetch: alertsRefetch,
  } = useQuery<IQueryAlertsPaginated>(query, {
    skip:
      !detection ||
      !detection?.tenant_info?.tenant_id ||
      !detection?.alert_entity?.entity_id,
    variables: noFilterVariables,
    onCompleted: (data) => {
      alertsGetPageCount(data?.queryAlertsPaginated);
      setSelectedItem(
        (data?.queryAlertsPaginated?.alerts?.[0] as
          | CustomSearchRuleAlert
          | MlAlert
          | NoticeAlert
          | SuricataAlert) ?? {},
      );
    },
  });

  useEffect(() => {
    alertsGetPageCount(alertsData?.queryAlertsPaginated);
  }, [alertsData?.queryAlertsPaginated]);

  const handleColFilters = (filters: DetectionAlertsFilters) => {
    setFilters(filters ?? null);
    if (filters.length) {
      alertsRefetch({
        ...noFilterVariables,
        offset: 0,
        query: `{"query": {"bool": {"must": [{"term": {"alert_info.content_id": "${
          detection?.alert_info?.content_id
        }"}}, {"term": {"alert_entity.entity_id": "${
          detection?.alert_entity?.entity_id
        }"}}${filters?.length > 0 ? ',' : ''}${filters
          .map((filter) =>
            filter.id === 'source_entities'
              ? `{"nested": {
                "path": "related_alert_entities",
                "query": {
                  "bool": {
                    "must": [
                      {"term": {"related_alert_entities.entity_category": {"value": "source"}}},
                      {"match_phrase": {"related_alert_entities.entity_name": {"query": "${filter.value}", "analyzer": "keyword"}}}
                    ]
                  }
                }
              }}`
              : filter.id === 'destination_entities'
                ? `{"nested": {
                "path": "related_alert_entities",
                "query": {
                  "bool": {
                    "must": [
                      {"term": {"related_alert_entities.entity_category": {"value": "destination"}}},
                      {"match_phrase": {"related_alert_entities.entity_name": {"query": "${filter.value}", "analyzer": "keyword"}}}
                    ]
                  }
                }
              }}`
                : '',
          )
          .join(',')},{"range": {"alert_timestamp.end": {"gte": ${
          detection?.earliest_start_timestamp
        }}}}, {"range": {"alert_timestamp.start": {"lte": ${
          detection?.latest_start_timestamp
        }}}}${detection?.tenant_info?.tenant_id ? ',' : ''}${
          detection?.tenant_info?.tenant_id
            ? `{"terms":{"tenant":["${detection?.tenant_info?.tenant_id}"]}}`
            : ''
        }]}}}`,
      });
    } else {
      alertsRefetch({
        ...noFilterVariables,
      });
    }
  };

  const handleFetchRelatedAlerts = useCallback(
    (pageIndex: number, pageSize: number, sortBy: SortingRule<Alert>[]) => {
      setAlertsSize(pageSize);
      setAlertsOffset(pageIndex * pageSize);
      onFetchAlertsData(pageIndex, pageSize, sortBy);
    },
    [],
  );

  const {
    alert_entity,
    alert_info,
    earliest_start_timestamp,
    latest_start_timestamp,
    logscale_query,
  } = detection || {};

  const widgetType = logscale_query ? 'table-view' : 'list-view';

  const query_string =
    metadataData?.getAlertMetadata?.metadata[0]?.custom_search_rule
      ?.query_string;

  const queryStringSearchBased = `?query=${
    logscale_query
      ? logscale_query && encodeURIComponent(logscale_query)
      : `${query_string && encodeURIComponent(query_string)}`
  }&live=false&humioStart=${
    earliest_start_timestamp === latest_start_timestamp
      ? (earliest_start_timestamp - 60) * 1000
      : earliest_start_timestamp * 1000
  }&humioEnd=${
    latest_start_timestamp === earliest_start_timestamp
      ? (latest_start_timestamp + 60) * 1000
      : latest_start_timestamp * 1000
  }&fullscreen=false&widgetType=${widgetType}&newestAtBottom=true&showOnlyFirstLine=false&start=${start}&end=${end}`;

  const totalItems = alertsData?.queryAlertsPaginated?.total_items;

  const alertType =
    alertsData?.queryAlertsPaginated?.alerts[0]?.alert_info?.alert_type;

  const alertsTabDescription =
    totalItems === 1
      ? i18n.formatString(
          alertActivity,
          String(totalItems),
          alert_entity?.entity_name,
          alert_info?.alert_name,
          moment.unix(latest_start_timestamp).format('MMMM Do, h:mma'),
        )
      : i18n.formatString(
          alertsActivity,
          String(totalItems),
          alert_entity?.entity_name,
          alert_info?.alert_name,
          moment.unix(earliest_start_timestamp).format('MMMM Do, h:mma'),
          moment.unix(latest_start_timestamp).format('MMMM Do, h:mma'),
        );

  const [activeAlert, setActiveAlert] = useState<
    | null
    | Alert
    | SanitizedMlAlert
    | SanitizedNoticeAlert
    | SanitizedSuricataAlert
    | SanitizedSearchBasedAlert
  >(null);

  const alerts = alertsData?.queryAlertsPaginated?.alerts;

  const sanitizedNoticeAlerts: Partial<NoticeAlert | Detection>[] = (
    alerts as NoticeAlert[]
  )?.map(
    ({
      uid,
      msg,
      alert_info,
      alert_entity,
      source_entities,
      destination_entities,
      alert_timestamp,
    }) => {
      return {
        uid,
        msg,
        alert_info,
        alert_entity,
        logscale_query,
        alert_timestamp,
        source_entities,
        destination_entities,
        timestamp: alert_timestamp?.observed,
      };
    },
  );

  const sanitizedSuricataAlerts: Partial<SuricataAlert | Detection>[] = (
    alerts as SuricataAlert[]
  )?.map(
    ({
      uid,
      alert_info,
      alert_entity,
      community_id,
      destination_ip,
      source_entities,
      destination_entities,
      alert_timestamp,
    }) => {
      return {
        uid,
        alert_info,
        alert_entity,
        logscale_query,
        alert_timestamp,
        community_id,
        destination_ip,
        source_entities,
        destination_entities,
        timestamp: alert_timestamp?.observed,
      };
    },
  );

  const sanitizedMlAlerts: Partial<MlAlert | Detection>[] = (
    alerts as MlAlert[]
  )?.map(
    ({
      models,
      pipeline,
      alert_info,
      alert_entity,
      alert_timestamp,
      source_entities,
      related_entities,
      destination_entities,
      average_technique_score_map,
    }) => {
      return {
        models,
        pipeline,
        alert_info,
        alert_entity,
        logscale_query,
        alert_timestamp,
        related_entities,
        average_technique_score_map,
        source_entities,
        destination_entities,
        timestamp: alert_timestamp?.observed,
      };
    },
  );

  const sanitizedSearchBasedAlerts: Partial<CustomSearchRuleAlert>[] = (
    alerts as CustomSearchRuleAlert[]
  )?.map(
    ({
      alert_info,
      alert_entity,
      alert_timestamp,
      source_entities,
      destination_entities,
    }) => {
      return {
        alert_info,
        alert_entity,
        logscale_query,
        alert_timestamp,
        source_entities,
        destination_entities,
        timestamp: alert_timestamp?.observed,
      };
    },
  );

  if (
    (detection?.alert_info?.alert_type === 'ml' && !sanitizedMlAlerts) ||
    (detection?.alert_info?.alert_type === 'notice' &&
      !sanitizedNoticeAlerts) ||
    (detection?.alert_info?.alert_type === 'custom_search_rule' &&
      !sanitizedSearchBasedAlerts) ||
    (detection?.alert_info?.alert_type === 'suricata_corelight' &&
      !sanitizedSuricataAlerts)
  ) {
    return (
      <Panel>
        <GraphQLReqStatus loading={true} />
      </Panel>
    );
  }
  const isLLMSummary = getAppConfigurationsData
    ? JSON.parse(getAppConfigurationsData).feature_flags.llm_summary
    : false;

  return alertsError ? (
    <Panel>
      <GraphQLReqStatus
        loading={false}
        isBackground={true}
        error={alertsError}
      />
    </Panel>
  ) : (
    <Panel>
      <FlushPanelContent>
        <VStack
          w="100%"
          spacing={0}
          alignItems="start"
          data-testid="detection-alerts"
        >
          <Flex
            px={4}
            py={5}
            w="100%"
            bg="layer.2"
            alignItems="center"
            justifyContent="space-between"
            borderBottomWidth="1px"
            borderBottomColor="white.300"
          >
            {!!alertsData?.queryAlertsPaginated?.total_items && (
              <Text
                data-testid={`${alertType}-alerts-tab-description`}
                textStyle="b1"
              >
                {alertsTabDescription}
              </Text>
            )}
            {detection?.alert_info?.alert_type === 'custom_search_rule' && (
              <Button
                size="sm"
                minWidth="auto"
                as={RouterLink}
                variant="solid"
                disabled={metadataLoading}
                data-testid="investigate-logs-button"
                leftIcon={
                  metadataLoading ? (
                    <LoadingSpinner size="sm" />
                  ) : (
                    <MuiIcon>frame_inspect</MuiIcon>
                  )
                }
                to={`${logSearch}${queryStringSearchBased}`}
              >
                {
                  i18n.pages.alertDetails.panels.entityDetails.buttons
                    .investigateLogs
                }
              </Button>
            )}
          </Flex>
          <Box width="100%">
            <Panel border={0}>
              <FlushPanelContent>
                {detection?.alert_info?.alert_type && (
                  <DataTable
                    isMemod={false}
                    hideColumnHeaders={alertsLoading}
                    isPaginable={Boolean(
                      !alertsLoading && !alertsError && totalItems,
                    )}
                    isFilterManual={true}
                    onColumnFilter={handleColFilters}
                    autoResetPage={alertsOffset / alertsSize >= alertsPageCount}
                    // @ts-ignore
                    pageSize={alertsPageSize}
                    pageCount={alertsPageCount}
                    // autoResetPage={false}
                    autoResetSortBy={false}
                    autoResetRowSelection={false}
                    isPaginationManual={true}
                    // @ts-ignore
                    columns={
                      detection?.alert_info?.alert_type === 'ml'
                        ? mlDataTableColumns
                        : detection?.alert_info?.alert_type === 'notice'
                          ? noticeDataTableColumns
                          : detection?.alert_info?.alert_type ===
                              'suricata_corelight'
                            ? suricataDataTableColumns
                            : detection?.alert_info?.alert_type ===
                                'custom_search_rule'
                              ? searchBasedDataTableColumns
                              : []
                    }
                    onFetchData={handleFetchRelatedAlerts}
                    itemCount={
                      alertsData?.queryAlertsPaginated?.total_items || 0
                    }
                    emptyBodyContent={
                      <Center m={4}>{controls.noResults}</Center>
                    }
                    // @ts-ignore
                    data={
                      detection?.alert_info?.alert_type === 'ml'
                        ? (sanitizedMlAlerts as SanitizedMlAlert[])
                        : detection?.alert_info?.alert_type === 'notice'
                          ? (sanitizedNoticeAlerts as SanitizedNoticeAlert[])
                          : detection?.alert_info?.alert_type ===
                              'suricata_corelight'
                            ? (sanitizedSuricataAlerts as SanitizedSuricataAlert[])
                            : detection?.alert_info?.alert_type ===
                                'custom_search_rule'
                              ? (sanitizedSearchBasedAlerts as SanitizedSearchBasedAlert[])
                              : []
                    }
                    initialState={{
                      pageIndex: alertsPageIndex,
                      sortBy: alertsSortBy,
                      // pageSize: alertsPageSize,
                    }}
                    onRowClick={({ original }) => {
                      onOpen();
                      setActiveAlert(original);
                    }}
                    footer={DetectionAlertsFooter({
                      loading: alertsLoading,
                      error: alertsError,
                      data: alertsData?.queryAlertsPaginated?.alerts,
                    })}
                  />
                )}
              </FlushPanelContent>
            </Panel>
          </Box>
        </VStack>
        {activeAlert && (
          <Drawer
            isOpen={isOpen}
            onOpen={onOpen}
            onClose={() => {
              onClose();
              setActiveAlert(null);
            }}
            data-testid="detection-alert-details-drawer"
            title={
              <>
                <Text>{`${activeAlert.alert_info.alert_name} | ${moment
                  .unix(activeAlert.alert_timestamp?.observed)
                  .format()}`}</Text>
                <Flex pt={3} gap={2}>
                  <HStack>
                    {activeAlert.alert_info.alert_type === 'ml' ? (
                      <MLButtonsCol
                        item={activeAlert as SanitizedMlAlert}
                        showContent={true}
                        logScaleQuery={logscale_query}
                      />
                    ) : activeAlert.alert_info.alert_type === 'notice' ? (
                      <NoticeButtonsCol
                        item={activeAlert as SanitizedNoticeAlert}
                        showContent={true}
                        logScaleQuery={logscale_query}
                      />
                    ) : activeAlert.alert_info.alert_type ===
                      'suricata_corelight' ? (
                      <SuricataButtonsCol
                        item={activeAlert as SanitizedSuricataAlert}
                        showContent={true}
                        logScaleQuery={logscale_query}
                      />
                    ) : null}
                  </HStack>
                  {isLLMSummary && downloadUrl && (
                    <Button
                      variant="solid"
                      colorScheme="gray"
                      as="a"
                      href={downloadUrl}
                      download="PCAP"
                      aria-label={controls.downloadPcap}
                      leftIcon={<MuiIcon>download</MuiIcon>}
                    >
                      {controls.downloadPcap}
                    </Button>
                  )}
                </Flex>
              </>
            }
            body={
              activeAlert.alert_info.alert_type === 'ml' ? (
                <AlertRowSplitML
                  item={activeAlert as SanitizedMlAlert}
                  loading={alertsLoading}
                  logScaleQuery={logscale_query}
                />
              ) : activeAlert.alert_info.alert_type === 'notice' ? (
                <AlertRowSplitNotice
                  item={activeAlert as SanitizedNoticeAlert}
                  loading={alertsLoading}
                />
              ) : activeAlert.alert_info.alert_type === 'suricata_corelight' ? (
                <AlertRowSplitSuricata
                  item={activeAlert as SanitizedSuricataAlert}
                  getAppConfigurationsData={getAppConfigurationsData}
                  loading={alertsLoading}
                  suricataRuleText={suricataRuleText}
                  setDownloadUrl={setDownloadUrl}
                  detectionData={detection}
                />
              ) : activeAlert.alert_info.alert_type === 'custom_search_rule' ? (
                <AlertRowSplitSearchBased
                  item={activeAlert as SanitizedSearchBasedAlert}
                  loading={alertsLoading}
                />
              ) : null
            }
          ></Drawer>
        )}
      </FlushPanelContent>
    </Panel>
  );
};
