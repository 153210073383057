import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  createHttpLink,
  InMemoryCache,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { CONSTANTS } from '@gamma/investigator/constants';
import {
  AuthProvider,
  CookieProvider,
  LogScaleProvider,
  OrgTenantsProvider,
} from '@gamma/investigator/context';
import { Amplify } from 'aws-amplify';
import { AuthOptions, createAuthLink } from 'aws-appsync-auth-link';
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link';

import { App } from './app';

Amplify.configure({
  userPoolId:
    window.__env__?.cognito.cognito_user_pool ??
    process.env.REACT_APP_COGNITO_USER_POOL,
  region:
    window.__env__?.cognito.cognito_region ??
    process.env.REACT_APP_COGNITO_REGION,
  userPoolWebClientId:
    window.__env__?.cognito.userPoolWebClientId ??
    process.env.REACT_APP_COGNITO_WEB_CLIENT_ID,
});

// Log any GraphQL errors or network error that occurred
const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.map(({ message, locations, path }) => {
      const errorMsg = `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`;
      return console.log(errorMsg);
    });
  }
  if (networkError) console.log(`[Network error]: ${networkError}`);
});

const { url, region, auth } = CONSTANTS.APPSYNC;

const appsyncLink = createHttpLink({ uri: url });

const appsyncLinkWithAuth = ApolloLink.from([
  errorLink,
  createAuthLink({ url, region, auth: auth as AuthOptions }),
  createSubscriptionHandshakeLink(
    { url, region, auth: auth as AuthOptions },
    appsyncLink as unknown as ApolloLink,
  ),
]);

const cache = new InMemoryCache();

export const client = process.env.NX_MOCK_API
  ? new ApolloClient({
      uri: CONSTANTS.MOCK_API.URL,
      cache,
    })
  : new ApolloClient({
      link: appsyncLinkWithAuth,
      cache,
    });

export const Root = () => {
  return (
    <ApolloProvider client={client}>
      <AuthProvider>
        <CookieProvider>
          <LogScaleProvider>
            <OrgTenantsProvider>
              <App />
            </OrgTenantsProvider>
          </LogScaleProvider>
        </CookieProvider>
      </AuthProvider>
    </ApolloProvider>
  );
};
