import { Box, useColorMode } from '@chakra-ui/react';
import {
  Detection,
  DetectionAlertGroupSummary,
  useGetDetectionTimelineSummary,
} from '@gamma/investigator/queries';
import { GraphQLReqStatus } from '@gamma/progress';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';

import _ from 'lodash';
import { RelatedDetectionsAlerts } from './Components/RelatedDetectionsAlerts';
import { ApexDetectionsChart } from './Components/RelatedDetectionsChart';

interface RelatedDetectionsProps {
  detection: Detection;
  suricataRuleText: string;
  onPreviewOpen: () => void;
  selectedDetection?: Detection;
  setSelectedDetectionId: React.Dispatch<
    React.SetStateAction<string | undefined>
  >;
  getDetectionsLoading: boolean;
  getAppConfigurationsData: string | null;
}

export const RelatedDetections = ({
  detection,
  onPreviewOpen,
  suricataRuleText,
  selectedDetection,
  getDetectionsLoading,
  setSelectedDetectionId,
  getAppConfigurationsData,
}: RelatedDetectionsProps) => {
  const { colorMode } = useColorMode();

  const [ts, setTs] = useState(0);

  const handleResize = () => {
    setTs(moment().unix());
  };
  window.onresize = _.debounce(handleResize, 500);

  useEffect(() => {
    setTs(moment().unix());
  }, [colorMode]);

  const { tenant, detection_id, latest_start_timestamp } = detection || {};

  const currentDateTime = moment().startOf('hour');
  const latestAlertDateTime = moment(latest_start_timestamp * 1000);

  const currentDiffLatest = moment
    .duration(currentDateTime.diff(latestAlertDateTime))
    .get('days');

  const endDateTime =
    currentDiffLatest >= 15
      ? latestAlertDateTime.add(15, 'days')
      : currentDateTime;

  const end = endDateTime.unix();
  const start = endDateTime.subtract(30, 'days').unix();

  const maxRows = 5;

  const [interval, setInterval] = useState<number>(86400);

  const { loading, error, data } = useGetDetectionTimelineSummary({
    fetchPolicy: 'network-only',
    skip: !tenant || !detection_id || !start || !end || !interval,
    variables: {
      end,
      start,
      interval,
      detection_id,
      top_n: maxRows,
      detection_tenant: tenant,
      severity: ['low', 'medium', 'high'],
    },
  });

  const {
    getCurrentDetectionTimelineSummary,
    getDetectionDestinationTimelineSummary,
    getDetectionSourceDestinationTimelineSummary,
    getDetectionSourceTimelineSummary,
  } = data || {};

  const currentDetectionDetections = useMemo(
    () =>
      getCurrentDetectionTimelineSummary?.summary
        ?.map((summary: DetectionAlertGroupSummary) => summary.detections)
        .flat()
        .sort((a, b) =>
          a.latest_start_timestamp < b.latest_start_timestamp ? 1 : -1,
        ),
    [getCurrentDetectionTimelineSummary],
  );

  const detectionDestinationDetections = useMemo(
    () =>
      getDetectionDestinationTimelineSummary?.summary
        ?.map((summary: DetectionAlertGroupSummary) => summary.detections)
        .flat()
        .sort((a, b) =>
          a.latest_start_timestamp < b.latest_start_timestamp ? 1 : -1,
        ),
    [getDetectionDestinationTimelineSummary],
  );

  const detectionSourceDestinationDetections = useMemo(
    () =>
      getDetectionSourceDestinationTimelineSummary?.summary
        ?.map((summary: DetectionAlertGroupSummary) => summary.detections)
        .flat()
        .sort((a, b) =>
          a.latest_start_timestamp < b.latest_start_timestamp ? 1 : -1,
        ),
    [getDetectionSourceDestinationTimelineSummary],
  );

  const detectionSourceDetections = useMemo(
    () =>
      getDetectionSourceTimelineSummary?.summary
        ?.map((summary: DetectionAlertGroupSummary) => summary.detections)
        .flat()
        .sort((a, b) =>
          a.latest_start_timestamp < b.latest_start_timestamp ? 1 : -1,
        ),
    [getDetectionSourceTimelineSummary],
  );

  const [chartDetections, setChartDetections] = useState<any>({});

  useEffect(() => {
    setChartDetections({
      currentDetectionDetections,
      detectionSourceDetections,
      detectionDestinationDetections,
      detectionSourceDestinationDetections,
    });
  }, [
    currentDetectionDetections,
    detectionSourceDetections,
    detectionDestinationDetections,
    detectionSourceDestinationDetections,
  ]);

  return loading || error ? (
    <GraphQLReqStatus error={error} loading={!!loading} />
  ) : (
    <Box key={ts}>
      <ApexDetectionsChart
        end={end}
        start={start}
        maxRows={maxRows}
        chartsData={data}
        interval={interval}
        setInterval={setInterval}
        setSelectedDetectionId={setSelectedDetectionId}
      />
      <Box id="tooltip" />
      <RelatedDetectionsAlerts
        detection={detection}
        onPreviewOpen={onPreviewOpen}
        chartDetections={chartDetections}
        suricataRuleText={suricataRuleText}
        selectedDetection={selectedDetection}
        getDetectionsLoading={getDetectionsLoading}
        setSelectedDetectionId={setSelectedDetectionId}
        getAppConfigurationsData={getAppConfigurationsData}
      />
    </Box>
  );
};
