import { Box, Flex, Tag, Text, useDisclosure, VStack } from '@chakra-ui/react';
import { MuiIcon } from '@gamma/display';
import { ChatGPTToolTip } from '@gamma/investigator/components';
import { AuthContext } from '@gamma/investigator/context';
import { i18n } from '@gamma/investigator/localization';
import { Panel } from '@gamma/layout';
import { LoadingSpinner } from '@gamma/progress';
import { useContext, useState } from 'react';

import { ChatGPTSettingsCardModal } from '../ChatGPTSettingsCardModal';

const { integrations } = i18n.pages;
const { available, enabled } = integrations;
const { GPTVersion, gptCardHoverText } = integrations.chatGPT;

export const ChatGPTSettingsCard = () => {
  const { userRole, isGPTEnabled, userPrivilegesLoading } =
    useContext(AuthContext);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [showHoverBackGround, setShowHoverBackGround] =
    useState<boolean>(false);

  return (
    <>
      <Panel
        w="300px"
        h="200px"
        onClick={onOpen}
        layerStyle="first"
        _hover={{ bgColor: 'blue.alpha500.10' }}
        onMouseEnter={() => setShowHoverBackGround(true)}
        onMouseLeave={() => setShowHoverBackGround(false)}
        cursor={
          userRole !== 'admin' || userPrivilegesLoading
            ? 'not-allowed'
            : 'pointer'
        }
      >
        <VStack spacing={userPrivilegesLoading ? 4 : 8}>
          {/* Logo and Text */}
          <Flex
            h="92px"
            flexDir="column"
            justifyContent="center"
            alignItems="center"
            mt={2}
          >
            {showHoverBackGround ? (
              <VStack mt={4}>
                <Text textStyle="h4">{GPTVersion}</Text>
                <Text textAlign="center" textStyle="body-md">
                  {gptCardHoverText}
                </Text>
              </VStack>
            ) : (
              <Box>
                <ChatGPTToolTip boxSize={16} />
                <Text mt={2} textStyle="h4">
                  {GPTVersion}
                </Text>
              </Box>
            )}
          </Flex>
          {/* Status Tag  and loading spinner */}
          {userPrivilegesLoading ? (
            <LoadingSpinner size="sm" />
          ) : (
            <Tag
              data-testid="chat-gpt-configuration-status"
              alignItems="center"
              justifyContent="center"
              backgroundColor={isGPTEnabled ? 'green.600' : 'gray.600'}
            >
              {isGPTEnabled ? (
                <MuiIcon mr={2}>check_circle</MuiIcon>
              ) : (
                <MuiIcon mr={2}>add_circle</MuiIcon>
              )}
              <Text textStyle="body-sm">
                {isGPTEnabled ? enabled : available}
              </Text>
            </Tag>
          )}
        </VStack>
      </Panel>
      {userRole === 'admin' && isOpen && isGPTEnabled !== null && (
        <ChatGPTSettingsCardModal
          isOpen={isOpen}
          onClose={onClose}
          isGPTEnabled={isGPTEnabled}
        />
      )}
    </>
  );
};
