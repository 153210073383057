import {
  Box,
  Button,
  Center,
  Flex,
  HStack,
  Text,
  VStack,
} from '@chakra-ui/react';
import { SeverityScore } from '@gamma/investigator/components';
import { Detection, DetectionAlertSummary } from '@gamma/investigator/queries';
import { AccordionPanel, FlushPanelContent } from '@gamma/layout';
import React from 'react';

import { i18n } from '@gamma/investigator/localization';
import { LoadingSpinner } from '@gamma/progress';
import { DetectionAlerts, RelatedDetectionRow } from './Components';

const { correlationChart } = i18n.pages.detections;

interface RelatedDetectionsAlertsProps {
  detection: Detection;
  suricataRuleText: string;
  onPreviewOpen: () => void;
  selectedDetection?: Detection;
  setSelectedDetectionId: React.Dispatch<
    React.SetStateAction<string | undefined>
  >;
  chartDetections?: any;
  getDetectionsLoading: boolean;
  getAppConfigurationsData: string | null;
}

export const RelatedDetectionsAlerts = React.memo(
  ({
    detection,
    onPreviewOpen,
    chartDetections,
    suricataRuleText,
    selectedDetection,
    getDetectionsLoading,
    setSelectedDetectionId,
    getAppConfigurationsData,
  }: RelatedDetectionsAlertsProps) => {
    const {
      detectionSourceDetections,
      currentDetectionDetections,
      detectionDestinationDetections,
      detectionSourceDestinationDetections,
    } = chartDetections;

    const isCurrentDetectionOpen =
      !!chartDetections?.currentDetectionDetections?.find(
        (detection: DetectionAlertSummary) =>
          detection.detection_id === selectedDetection?.detection_id,
      );

    const isDetectionDestinationOpen =
      !!chartDetections?.detectionDestinationDetections?.find(
        (detection: DetectionAlertSummary) =>
          detection.detection_id === selectedDetection?.detection_id,
      );

    const isDetectionSourceOpen =
      !!chartDetections?.detectionSourceDetections?.find(
        (detection: DetectionAlertSummary) =>
          detection.detection_id === selectedDetection?.detection_id,
      );

    const isDetectionSourceDestinationOpen =
      !!chartDetections?.detectionSourceDestinationDetections?.find(
        (detection: DetectionAlertSummary) =>
          detection.detection_id === selectedDetection?.detection_id,
      );

    return (
      <FlushPanelContent mt={0}>
        <HStack
          spacing={0}
          alignItems="start"
          borderTop="solid 1px"
          borderTopColor="border.1"
        >
          <Flex w="280px" borderRight="solid 1px" borderRightColor="border.1">
            <VStack w="100%">
              {currentDetectionDetections?.length > 0 && (
                <Box
                  w="100%"
                  borderBottom="solid 1px"
                  borderBottomColor="border.1"
                >
                  <AccordionPanel
                    w="100%"
                    disableBorders
                    title="Current Detection"
                    expanded={isCurrentDetectionOpen}
                  >
                    <FlushPanelContent
                      borderTop="solid 1px"
                      borderTopColor="border.1"
                    >
                      {currentDetectionDetections.map(
                        (detection: any, index: number) => (
                          <RelatedDetectionRow
                            key={index}
                            item={detection}
                            selectedDetection={selectedDetection}
                            setSelectedDetectionId={setSelectedDetectionId}
                          />
                        ),
                      )}
                    </FlushPanelContent>
                  </AccordionPanel>
                </Box>
              )}
              {detectionSourceDestinationDetections?.length > 0 && (
                <Box
                  w="100%"
                  borderBottom="solid 1px"
                  borderBottomColor="border.1"
                >
                  <AccordionPanel
                    w="100%"
                    disableBorders
                    title="Source - Dest. Pair"
                    expanded={isDetectionSourceDestinationOpen}
                  >
                    <FlushPanelContent
                      borderTop="solid 1px"
                      borderTopColor="border.1"
                    >
                      {detectionSourceDestinationDetections.map(
                        (detection: any, index: number) => (
                          <RelatedDetectionRow
                            key={index}
                            item={detection}
                            selectedDetection={selectedDetection}
                            setSelectedDetectionId={setSelectedDetectionId}
                          />
                        ),
                      )}
                    </FlushPanelContent>
                  </AccordionPanel>
                </Box>
              )}
              {detectionSourceDetections?.length > 0 && (
                <Box
                  w="100%"
                  borderBottom="solid 1px"
                  borderBottomColor="border.1"
                >
                  <AccordionPanel
                    w="100%"
                    disableBorders
                    title="Source Only"
                    expanded={isDetectionSourceOpen}
                  >
                    <FlushPanelContent
                      borderTop="solid 1px"
                      borderTopColor="border.1"
                    >
                      {detectionSourceDetections.map(
                        (detection: any, index: number) => (
                          <RelatedDetectionRow
                            key={index}
                            item={detection}
                            selectedDetection={selectedDetection}
                            setSelectedDetectionId={setSelectedDetectionId}
                          />
                        ),
                      )}
                    </FlushPanelContent>
                  </AccordionPanel>
                </Box>
              )}
              {detectionDestinationDetections?.length > 0 && (
                <Box
                  w="100%"
                  borderBottom="solid 1px"
                  borderBottomColor="border.1"
                >
                  <AccordionPanel
                    w="100%"
                    disableBorders
                    title="Destination Only"
                    expanded={isDetectionDestinationOpen}
                  >
                    <FlushPanelContent
                      borderTop="solid 1px"
                      borderTopColor="border.1"
                    >
                      {detectionDestinationDetections.map(
                        (detection: any, index: number) => (
                          <RelatedDetectionRow
                            key={index}
                            item={detection}
                            selectedDetection={selectedDetection}
                            setSelectedDetectionId={setSelectedDetectionId}
                          />
                        ),
                      )}
                    </FlushPanelContent>
                  </AccordionPanel>
                </Box>
              )}
            </VStack>
          </Flex>
          <Flex flexGrow={1}>
            <VStack p={4} w="full" spacing={4} alignItems="start">
              <HStack w="full" justifyContent="space-between">
                <HStack>
                  {selectedDetection?.rank?.severity !== null &&
                    selectedDetection?.rank?.severity !== undefined && (
                      <SeverityScore
                        score={selectedDetection.rank.severity}
                        isCustom={selectedDetection?.rank?.is_custom_severity}
                      />
                    )}
                  <Text textStyle="h5">
                    {selectedDetection?.alert_info?.alert_name}
                  </Text>
                </HStack>
                <Button
                  size="xs"
                  variant="solid"
                  colorScheme="blue"
                  onClick={onPreviewOpen}
                  isDisabled={
                    detection.detection_id === selectedDetection?.detection_id
                  }
                >
                  {correlationChart.previewDetection}
                </Button>
              </HStack>
              {getDetectionsLoading && (
                <Center pt={4} width="100%">
                  <LoadingSpinner />
                </Center>
              )}
              {selectedDetection && (
                <Box w="100%">
                  <DetectionAlerts
                    detectionData={selectedDetection}
                    suricataRuleText={suricataRuleText}
                    getAppConfigurationsData={getAppConfigurationsData}
                  />
                </Box>
              )}
            </VStack>
          </Flex>
        </HStack>
      </FlushPanelContent>
    );
  },
);
