import { ApolloError } from '@apollo/client';
import {
  Button,
  HStack,
  Link,
  Text,
  VStack,
  useDisclosure,
} from '@chakra-ui/react';
import { MarkdownRenderer } from '@gamma/display';
import { ROUTES } from '@gamma/investigator/constants';
import { AuthContext } from '@gamma/investigator/context';
import { useGetHasUserAcceptedAnalytics } from '@gamma/investigator/hooks';
import { i18n } from '@gamma/investigator/localization';
import { initializePendo } from '@gamma/investigator/utilities';
import { AccordionPanel as GammaAccordionPanel, Panel } from '@gamma/layout';
import { GraphQLReqStatus, LoadingSpinner } from '@gamma/progress';
import { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import { EnableCookiesModal } from '../../EnableCookiesModal';
import { ChatGPTLoadingQuestionPrompt } from '../ChatGPTLoadingQuestionPrompt';
import { ChatGPTQuestionPrompt } from '../ChatGPTQuestionPrompt';
import { ChatGPTToolTip } from '../ChatGPTToolTip';
import { SkeletonLoader } from '../SkeletonLoader';

const {
  askGPT,
  requestMore,
  settings,
  gptOffAnalystMessage,
  gptOffAdminMessage,
  questionPrompt,
  description,
} = i18n.pages.integrations.chatGPT;

const { systemSettingsIntegrationsConfig } = ROUTES;

export interface ChatGPTAnswer {
  answerText: string;
  questionText: string;
}

export interface ChatGPTQAPromptCloudPanelProps {
  titleText?: string;
  suricataRuleText?: string;
  descriptionText?: string;
  isLoadingDescription?: boolean;
  questionsTextList?: string[];
  answersTextList?: ChatGPTAnswer[];
  isLoadingAnswerList?: boolean;
  questionLoadingText?: string;
  loadingAnswerListError?: ApolloError;
  onQuestionClick?: (questionText: string, hasError: boolean) => void;
  storageKey?: string;
  isAccordion?: boolean;
}

export const ChatGPTQAPromptCloudPanel = ({
  titleText = description,
  questionsTextList,
  answersTextList,
  descriptionText,
  isLoadingDescription,
  isLoadingAnswerList,
  questionLoadingText,
  loadingAnswerListError,
  onQuestionClick,
  storageKey,
  isAccordion,
}: ChatGPTQAPromptCloudPanelProps) => {
  const { user, userRole, userLicense, isGPTEnabled, userPrivilegesLoading } =
    useContext(AuthContext);

  const answersTextListRef = useRef<HTMLDivElement>(null);

  const navigate = useNavigate();

  const getHasUserAcceptedAnalytics = useGetHasUserAcceptedAnalytics();

  const [pendoGuideType, setPendoGuideType] = useState<string>('');

  const {
    onClose: onCloseAnalyticsModal,
    onOpen: onOpenAnalyticsModal,
    isOpen: isEnableCookiesModalOpen,
  } = useDisclosure();

  const pendoRequestMoreGuideName = 'Investigator_GPT_RequestMore';
  const pendoDescriptionGuideName = 'Investigator_GPT_RequestDescription';

  const hasUserAcceptedAnalytics = getHasUserAcceptedAnalytics();

  const updateURLParams = () => {
    const url = new URL(window.location.href);

    if (pendoGuideType === 'request_more') {
      url.searchParams.set('showPendoRequestMoreButtonGuide', 'true');
      navigate(url.pathname.toString() + url.search.toString());
    }

    if (pendoGuideType === 'description') {
      url.searchParams.set('showPendoDescriptionButtonGuide', 'true');
      navigate(url.pathname.toString() + url.search.toString());
    }
  };

  const handleShowPendoGuideByName = (type: 'description' | 'request_more') => {
    if (!hasUserAcceptedAnalytics) {
      setPendoGuideType(type);
      onOpenAnalyticsModal();
    } else {
      if (type === 'description') {
        window?.pendo?.showGuideByName(pendoDescriptionGuideName);
      }

      if (type === 'request_more') {
        window?.pendo?.showGuideByName(pendoRequestMoreGuideName);
      }
    }
  };

  const isLoadingContent = isLoadingAnswerList || isLoadingDescription;

  const [urlParamsForPendoGuide, setURLParamsForPendoGuide] =
    useState<string>('');

  // handle scroll position
  useEffect(() => {
    if (answersTextListRef?.current?.scrollTo) {
      answersTextListRef.current.scrollTo({
        top: answersTextListRef.current.scrollHeight,
        behavior: 'smooth',
      });
    }
  }, [answersTextList, isLoadingAnswerList]);

  // update url params for pendo guide when present in URL
  useEffect(() => {
    if (isGPTEnabled) {
      const url = new URL(window.location.href);
      const showPendoRequestMoreButtonGuide = url.searchParams.get(
        'showPendoRequestMoreButtonGuide',
      );
      const showPendoDescriptionButtonGuide = url.searchParams.get(
        'showPendoDescriptionButtonGuide',
      );

      if (showPendoDescriptionButtonGuide === 'true') {
        setURLParamsForPendoGuide('showPendoDescriptionButtonGuide');
      }

      if (showPendoRequestMoreButtonGuide === 'true') {
        setURLParamsForPendoGuide('showPendoRequestMoreButtonGuide');
      }
    }
  }, [userPrivilegesLoading, isGPTEnabled]);

  // watch URL for pendo key and show guide when present
  useEffect(() => {
    if (urlParamsForPendoGuide && isGPTEnabled) {
      initializePendo({
        user,
        userLicense,
        isCookieAccepted: true,
      });
      if (urlParamsForPendoGuide === 'showPendoDescriptionButtonGuide') {
        window?.pendo?.showGuideByName(pendoDescriptionGuideName);
      }

      if (urlParamsForPendoGuide === 'showPendoRequestMoreButtonGuide') {
        window?.pendo?.showGuideByName(pendoRequestMoreGuideName);
      }

      const url = new URL(window.location.href);
      url.searchParams.delete('showPendoRequestMoreButtonGuide');
      url.searchParams.delete('showPendoDescriptionButtonGuide');
      navigate(url.pathname.toString() + url.search.toString());
    }
  }, [
    urlParamsForPendoGuide,
    user,
    userLicense,
    isGPTEnabled,
    userPrivilegesLoading,
    navigate,
  ]);

  const showAnswers =
    (answersTextList || [])?.length > 0 ||
    (isLoadingContent &&
      questionLoadingText &&
      !loadingAnswerListError &&
      !userPrivilegesLoading);

  const shouldShowGPTToolTipIcon = !isLoadingContent;

  const GPTPromptContents = () => {
    return (
      <>
        <Text color="text.secondary">{askGPT}</Text>
        {/* GPT is unset */}
        {isGPTEnabled === null &&
          (userPrivilegesLoading || isLoadingContent) && <LoadingSpinner />}

        {/* GPT is disabled */}
        {isGPTEnabled === false && !isLoadingContent && (
          <VStack alignItems="start" justifyContent="center">
            <Text
              data-testid="chat-gpt-is-disabled-privileges"
              textStyle="body-md"
            >
              {userRole === 'admin'
                ? i18n.formatString(gptOffAdminMessage, questionPrompt)
                : gptOffAnalystMessage}
            </Text>
            {userRole === 'admin' && (
              <Button
                data-testid="chat-gpt-is-disabled-settings-button"
                onClick={() => navigate(systemSettingsIntegrationsConfig)}
                variant="solid"
                colorScheme="gray"
              >
                {settings}
              </Button>
            )}
          </VStack>
        )}

        {/* user has not accepted user analytics modal */}
        {isGPTEnabled && !isLoadingDescription && (
          <EnableCookiesModal
            isOpen={isEnableCookiesModalOpen}
            onClose={onCloseAnalyticsModal}
            updateURLParams={updateURLParams}
            setPendoGuideType={setPendoGuideType}
            pendoGuideType={pendoGuideType}
          />
        )}

        {/* answers area */}
        {isGPTEnabled && showAnswers && (
          <Panel
            ref={answersTextListRef}
            maxH="500px"
            overflowY="scroll"
            flexDir="column"
            border="none"
            p={0}
          >
            <VStack>
              {answersTextList?.map((answer) => (
                <Panel layerStyle="first" key={uuidv4()}>
                  <VStack alignItems="start">
                    <ChatGPTQuestionPrompt text={answer.questionText} />
                    <MarkdownRenderer
                      data-testid={`answers-list-text`}
                      children={answer.answerText}
                    />
                  </VStack>
                </Panel>
              ))}
            </VStack>
            {/* is loading new question loading to answer list... */}
            {isLoadingAnswerList && (
              <Panel key={uuidv4()} layerStyle="first">
                <ChatGPTLoadingQuestionPrompt
                  text={questionLoadingText ?? ''}
                />
                <SkeletonLoader />
              </Panel>
            )}
          </Panel>
        )}

        {/* answers area error */}
        {isGPTEnabled && loadingAnswerListError && !isLoadingContent && (
          <GraphQLReqStatus error={loadingAnswerListError} loading={false} />
        )}

        {/* questions area */}
        {isGPTEnabled && questionsTextList && !isLoadingContent && (
          <VStack alignItems="start">
            {!loadingAnswerListError && questionsTextList?.length > 0 && (
              <VStack alignItems="start">
                {questionsTextList?.map((questionText) => (
                  <ChatGPTQuestionPrompt
                    isDisabled={isLoadingAnswerList}
                    onClick={onQuestionClick}
                    key={uuidv4()}
                    text={questionText}
                    hasError={Boolean(loadingAnswerListError)}
                  />
                ))}
              </VStack>
            )}
            <Link
              color="text.link"
              data-testid="chat-gpt-request-more-button"
              onClick={() => handleShowPendoGuideByName('request_more')}
            >
              {requestMore}
            </Link>
          </VStack>
        )}
      </>
    );
  };

  return isAccordion ? (
    <GammaAccordionPanel
      w="100%"
      layerStyle="first"
      reduceMotion={true}
      storageKey={storageKey}
      title={
        <HStack justifyContent="center" alignItems="center">
          {shouldShowGPTToolTipIcon && <ChatGPTToolTip />}
          <Text>{titleText}</Text>
        </HStack>
      }
    >
      <GPTPromptContents />
    </GammaAccordionPanel>
  ) : (
    <GPTPromptContents />
  );
};
